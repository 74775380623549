<template>
  <v-row justify="center">
    <v-col sm="10">
      <v-card>
        <v-card-title>
          {{ tableName }}
          <v-spacer />
          <v-text-field
            v-model="search"
            append-icon="mdi-magnify"
            label="Search"
            single-line
            hide-details
          />
        </v-card-title>
        <v-data-table
          :headers="headers"
          :items="tableData"
          :search="search"
        >
          <!-- eslint-disable-next-line -->
          <template #item.createdAt="{ item }">
            <span>{{ new Date(item.createdAt).toLocaleString() }}</span>
          </template>
          <!-- eslint-disable-next-line -->
          <!-- <template #item.finishedAt="{ item }">
            <span>{{ new Date(item.finishedAt).toLocaleString() }}</span>
          </template> -->
        </v-data-table>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
export default {
  props: {
    tableData: {
      type: Array,
      required: true
    },
    tableName: {
      type: String,
      required: true
    },
    headers: {
      type: Array,
      required: true
    }
  },
  data () {
    return {
      search: ''
    }
  }
}
</script>
