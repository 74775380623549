<template>
  <v-card
    class="mx-auto"
    max-width="400"
  >
    <v-card-title>
      {{ creationData.kind }}
    </v-card-title>

    <v-card-text>
      <p class="headline text--primary">
        {{ creationData.primaryText }}
      </p>
      <p class="title text--primary">
        {{ creationData.secondaryText }}
      </p>
    </v-card-text>
  </v-card>
</template>

<script>
export default {
  name: 'InformationTiles',
  props: {
    creationData: {
      type: Object,
      required: true
    }
  }
}
</script>
