<template>
  <v-row
    v-if="taskData != null && me != null"
    justify="center"
    class="fill-height text-center py-2"
  >
    <v-col cols="12">
      <h1>{{ $t('taskDetail.title') }}</h1>
    </v-col>
    <v-col cols="12">
      <h1
        v-if="taskData.machineError.name != null"
        class="mb-4"
      >
        {{ taskData.machineError.name }}
      </h1>
      <h2 class="mb-4">
        Status: {{ status }}
      </h2>
      <p style="font-size: 20px;">
        {{ $t('taskDetail.description') }}: {{ taskData.machineError.description }}
      </p>
    </v-col>
    <v-col
      cols="12"
      class="d-none d-sm-block"
    >
      <v-row
        v-if="taskData != null && me != null"
        justify="center"
        class="text-center flex-grow-1 justify-space-around align-stretch"
      >
        <v-col
          sm="6"
          cols="12"
          class="fill-height"
        >
          <information-tiles
            v-if="taskData !== null"
            :creation-data="userTile"
          />
        </v-col>
        <v-col
          sm="6"
          cols="12"
          class="fill-height"
        >
          <information-tiles
            v-if="taskData !== null"
            :creation-data="machineTile"
          />
        </v-col>
      </v-row>
    </v-col>
    <v-col cols="12">
      <v-btn
        :disabled="!canStartTask"
        x-large
        width="200"
        color="primary"
        class="ma-1"
        @click="startTask"
      >
        {{ $t('taskDetail.startTask') }}
      </v-btn>
      <v-btn
        :disabled="!canEndTask"
        x-large
        width="200"
        color="primary"
        class="ma-1"
        @click="endTask"
      >
        {{ $t('taskDetail.endTask') }}
      </v-btn>
    </v-col>
    <v-col
      v-if="isPaused"
      cols="12"
    >
      <div style="text-align: center;">
        {{ $t('taskDetail.pauseReason') }}:
      </div>
      <v-col sm="3">
        <p>{{ taskData.activePause.description }}</p>
      </v-col>
    </v-col>
    <v-col cols="12">
      <v-dialog
        transition="dialog-top-transition"
        max-width="100%"
      >
        <template #activator="{ on, attrs }">
          <v-btn
            :disabled="!canStartPause"
            x-large
            width="200"
            color="primary"
            class="ma-1"
            v-bind="attrs"
            v-on="on"
          >
            {{ $t('taskDetail.startPause') }}
          </v-btn>
        </template>
        <template #default="dialog">
          <v-card>
            <v-toolbar
              color="primary"
              dark
            >
              {{ $t('taskDetail.pause') }}
            </v-toolbar>
            <v-card-text>
              <div class="py-12">
                <v-textarea
                  v-model="pauseDesc"
                  outlined
                  name="pauseDesc"
                  label="Popis"
                />
              </div>
            </v-card-text>
            <v-card-actions class="justify-end">
              <v-btn
                text
                @click="dialog.value = false"
              >
                {{ $t('taskDetail.close') }}
              </v-btn>
              <v-btn
                x-large
                class="ma-1"
                color="primary"
                @click="() => { startPause(); dialog.value = false; }"
              >
                {{ $t('taskDetail.startPause') }}
              </v-btn>
            </v-card-actions>
          </v-card>
        </template>
      </v-dialog>
      <v-btn
        :disabled="!canEndPause"
        x-large
        width="200"
        class="ma-1"
        color="primary"
        @click="finishPause"
      >
        {{ $t('taskDetail.endPause') }}
      </v-btn>
    </v-col>
    <v-col cols="12">
      <v-dialog
        transition="dialog-top-transition"
        max-width="600"
      >
        <template #activator="{ on, attrs }">
          <v-btn
            :disabled="!canCallHelp"
            x-large
            color="primary"
            width="200"
            class="ma-1"
            v-bind="attrs"
            v-on="on"
          >
            {{ $t('taskDetail.help') }}
          </v-btn>
        </template>
        <template #default="dialog">
          <v-card>
            <v-toolbar
              color="primary"
              dark
            >
              {{ $t('taskDetail.help') }}
            </v-toolbar>
            <v-card-text>
              <div class="pa-12">
                <v-select
                  v-model="amount"
                  :items="items"
                  :label="$t('taskDetail.label')"
                />
              </div>
            </v-card-text>
            <v-card-actions class="justify-end">
              <v-btn
                text
                @click="dialog.value = false"
              >
                Zatvoriť
              </v-btn>
              <v-btn
                x-large
                class="ma-1"
                color="primary"
                @click="() => { askForHelp(); dialog.value = false; }"
              >
                {{ $t('taskDetail.help') }}
              </v-btn>
            </v-card-actions>
          </v-card>
        </template>
      </v-dialog>
      <v-btn
        :disabled="!canStealTask"
        x-large
        color="primary"
        width="200"
        class="ma-1"
        @click="stealTask"
      >
        {{ $t('taskDetail.steal') }}
      </v-btn>
    </v-col>
    <v-row
      align="center"
      justify="center"
    >
      <v-col sm="3">
        <v-alert
          v-if="error"
          max-width="400"
          type="error"
          dismissible
        >
          {{ $t('taskDetail.wrong') }}
        </v-alert>
        <v-alert
          v-if="success"
          style="cursor: pointer;"
          max-width="400"
          type="success"
          dismissible
          @click="goToError"
        >
          {{ $t('taskDetail.great') }}
        </v-alert>
        <v-alert
          v-if="helpSuccess"
          style="cursor: pointer;"
          max-width="400"
          type="success"
          dismissible
          @click="goToError"
        >
          {{ $t('taskDetail.found') }}
        </v-alert>
      </v-col>
    </v-row>
    <v-row>
      <pre
        style="text-align: left; padding: 16px; display: block; overflow: auto;"
        class="history-log"
      >
        {{ taskData.machineError.log }}
      </pre>
    </v-row>
    <v-col cols="12">
      <error-history-table
        v-if="previousMachineErrors"
        :table-name="$t('taskDetail.table.name')"
        :headers="headers"
        :table-data="previousMachineErrors"
      />
    </v-col>
  </v-row>
</template>

<script>
import InformationTiles from './InformationTiles'
import ErrorHistoryTable from './ErrorHistoryTable'

export default {
  name: 'ErrorDetail',
  components: {
    InformationTiles,
    ErrorHistoryTable
  },
  data () {
    return {
      taskData: null,
      success: false,
      error: false,
      helpSuccess: false,
      amount: 1,
      items: [1, 2, 3],
      previousMachineErrors: null,
      pauseDesc: '',
      me: null,
      headers: [
        { text: this.$t('taskDetail.table.errorName'), sortable: false, value: 'errorCategory.name' },
        { text: this.$t('taskDetail.table.errorMainCategory'), value: 'errorCategory.mainCategory' },
        { text: this.$t('taskDetail.table.errorCreationTime'), value: 'createdAt' },
        { text: this.$t('taskDetail.table.neededMaintainer'), value: 'neededMaintainers' }
        // { text: 'Maximum', value: 'tasks.finishedAt' }
      ]
    }
  },
  computed: {
    isMineTask () {
      return this.taskData.userId === this.me.id
    },
    isMineBetweeenSiblingTasks () {
      return this.taskData.machineError.tasks.every(t => t.userId === this.me.id)
    },
    isPaused () {
      return this.taskData.activePause != null && this.isStarted && !this.isFinished
    },
    isStarted () {
      return this.taskData.machineError.startedAt != null
    },
    isMainTask () {
      return this.taskData.machineError.mainTask.id === this.taskData.id
    },
    isFinished () {
      return this.taskData.machineError.finishedAt != null
    },
    hasCapacity () {
      return this.me.canClaimMachineError
    },
    canStealTask () {
      return !this.isMineTask && this.isPaused && !this.isMineBetweeenSiblingTasks && this.hasCapacity
    },
    canStartTask () {
      return this.isMineTask && this.hasCapacity && !this.isStarted
    },
    canEndTask () {
      return this.isMineTask && this.isStarted && !this.isPaused && this.isMainTask && !this.isFinished
    },
    canStartPause () {
      return this.isMineTask && !this.isPaused && !this.isFinished && this.isStarted
    },
    canEndPause () {
      return this.isMineTask && this.isPaused && !this.isFinished && this.isStarted
    },
    canCallHelp () {
      return this.isMineTask && this.isMainTask && this.isStarted && !this.isFinished
    },
    userTile () {
      if (!this.taskData.user) return {}
      return {
        kind: 'Informácie údržbárovi',
        icon: 'mdi-account',
        primaryText: this.$t('taskDetail.name') + ': ' + this.taskData.user.firstName + ' ' + this.taskData.user.lastName,
        secondaryText: this.$t('taskDetail.description') + ': ' + this.taskData.user.email
      }
    },
    machineTile () {
      if (!this.taskData === null) return {}
      return {
        kind: 'Informácie o stroji',
        icon: 'mdi-robot',
        primaryText: this.$t('taskDetail.name') + ': ' + this.taskData.machineError.machine.name,
        secondaryText: 'Umiestnenie' + ': ' + this.taskData.machineError.machine.hall.name
      }
    },
    status () {
      if (this.taskData.status === 'PAUSED') {
        return this.$t('taskDetail.paused')
      } else if (!this.taskData.machineError.startedAt) {
        return this.$t('taskDetail.notStarted')
      } else if (this.taskData.machineError.startedAt && !this.taskData.machineError.finishedAt) {
        return this.$t('taskDetail.inProgress')
      } else {
        return this.$t('taskDetail.done')
      }
    }
  },
  created () {
    this.getData()
  },
  methods: {
    async getData () {
      try {
        const { data } = await this.$axios.get(`/tasks/${this.$route.params.id}/?select=*,user(firstName,lastName,email),machineError(id,name,description,log,machine(*),mainTask(id,userId),tasks(id,userId),startedAt,finishedAt)`)
        const res2 = await this.$axios.get(`machine-errors/?filter.machineId=${data.machineError.machine.id}`)
        this.taskData = data
        console.log(this.taskData)
        this.previousMachineErrors = res2.data
      } catch (err) {
        console.error(err)
      }

      try {
        const { data } = await this.$axios.get('/users/me')
        this.me = data
      } catch (err) {
        console.error(err)
      }
    },
    async stealTask () {
      try {
        this.error = false
        const { data } = await this.$axios.post(`/tasks/${this.$route.params.id}/steal?select=*,user(firstName,lastName,email),machineError(id,name,description,log,machine(*),mainTask(id,userId),tasks(id,userId),startedAt,finishedAt)`)
        Object.assign(this.taskData, data)
      } catch (err) {
        this.error = true
        console.log(err)
      }
    },
    async startTask () {
      try {
        this.error = false
        const { data } = await this.$axios.post(`/machine-errors/${this.taskData.machineError.id}/start?select=id,name,description,log,machine(*),mainTask(id,userId),tasks(id,userId),startedAt,finishedAt`)
        Object.assign(this.taskData.machineError, data)
      } catch (err) {
        this.error = true
        console.log(err)
      }
    },
    async endTask () {
      try {
        this.success = false
        this.error = false
        const { data } = await this.$axios.post(`/machine-errors/${this.taskData.machineError.id}/end?select=id,name,description,log,machine(*),mainTask(id,userId),tasks(id,userId),startedAt,finishedAt`)
        console.log(data)
        this.success = true
        Object.assign(this.taskData.machineError, data)
      } catch (err) {
        this.error = true
        console.log(err)
      }
    },
    async startPause () {
      try {
        const { data } = await this.$axios.post(
          `/tasks/${this.$route.params.id}/start-pause?select=activePause,machineError(id,name,description,log,machine(*),mainTask(id,userId),tasks(id,userId),startedAt,finishedAt)`,
          {
            description: this.pauseDesc
          }
        )
        this.pauseDesc = ''
        Object.assign(this.taskData, data)
      } catch (err) {
        console.error(err)
      }
    },
    async finishPause () {
      try {
        const { data } = await this.$axios.post(`/tasks/${this.$route.params.id}/finish-pause?select=activePause,machineError(id,name,description,log,machine(*),mainTask(id,userId),tasks(id,userId),startedAt,finishedAt)`)
        Object.assign(this.taskData, data)
      } catch (err) {
        console.error(err)
      }
    },
    goToError () {
      this.$router.push({ name: 'CurrentMachineErrors' })
    },
    async askForHelp () {
      try {
        this.helpSuccess = false
        await this.$axios.post(`/tasks/${this.$route.params.id}/call-help`, {
          neededMaintainers: this.amount
        })
        this.helpSuccess = true
        this.$router.push({ name: 'CurrentMachineErrors' })
      } catch (err) {
        console.error(err)
      }
    }
  }
}
</script>

<style lang="scss" scoped>
@media screen and (max-width: 430px) {
  pre.history-log {
    width: 375px;
    font-size: 14px;
  }
}
</style>
